<template>
    <div>
        <div>
            <b-card no-body>
                <b-tabs card>
                    <div class="col-md-10 w-100 mx-auto">
                        <div v-if="!loadingData">
                            <b-tab title="Utiliser des requêtes SQL">
                                <b-card-text>
                                    <b-input-group class="mb-1 w-25 d-flex ml-auto">
                                        <b-input-group-prepend>
                                            <b-input-group-text>
                                                <feather-icon icon="SearchIcon"></feather-icon>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input v-model="searchQuery" />
                                    </b-input-group>

                                    <b-table ref="table" striped responsive :fields="tableFields" :items="filteredData" :current-page="currentPage" :per-page="perPage">
                                        <template v-slot:cell(Code)="row">
                                            <span>{{ row.item.Code }}</span>
                                        </template>
                                        <template v-slot:cell(Name)="row">
                                            <span>{{ row.item.Name }}</span>
                                        </template>
                                        <template v-slot:cell(Description)="row">
                                            <span>{{ row.item.Description }}</span>
                                        </template>
                                        <template v-slot:cell(Base)="row">
                                            <span>{{ row.item.Base }}</span>
                                        </template>
                                        <template v-slot:cell(Details)="row">
                                            <span>{{ row.item.Details }}</span>
                                        </template>
                                        <template v-slot:cell(Request)="row">
                                            <b-button variant="info" @click="showModal(row.item.Request, row.item.Code, row.item.Details)" class="mx-1 mb-1">Afficher</b-button>
                                            <b-button
                                                variant="primary"
                                                @click="modalUse(row.item.Request, row.item.Details, row.item.Base, row.item.Description, row.item.Name)"
                                                class="mx-1 mb-1"
                                                v-if="row.item.Request.startsWith('SELECT')"
                                                >Utiliser
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination v-model="currentPage" :total-rows="filteredData.length" :per-page="perPage" class="my-4" />
                                </b-card-text>
                            </b-tab>

                            <b-tab v-if="role == 1" title="Ajouter des requêtes SQL">
                                <b-card-text>
                                    <div>
                                        <div class="row">
                                            <div class="col-md-4 mb-3">
                                                <input type="text" class="form-control" v-model="requestData.name" placeholder="Name" />
                                            </div>
                                            <div class="col-md-8 mb-3">
                                                <input type="text" class="form-control" v-model="requestData.description" placeholder="Description" />
                                            </div>
                                        </div>
                                        <div>
                                            <select class="form-control md-18 mb-5" v-model="requestData.base">
                                                <option value="" selected disabled>Choisissez une base</option>
                                                <option v-for="baseName in baseName" :key="baseName" :value="baseName">{{ baseName }}</option>
                                            </select>
                                        </div>
                                        <textarea type="text" class="form-control md-18 mb-5" v-model="requestData.code" placeholder="SQL Request"></textarea>
                                        <div class="form-group col-md-2 mb-3">
                                            <label :for="searchArgumentCount">Nombre d'arguments:</label>
                                            <input type="number" class="form-control" v-model="searchArgumentCount" @input="generateArgumentName" />
                                        </div>

                                        <div v-for="i in argumentFields.length" :key="i">
                                            <div class="row">
                                                <div class="col-md-4 mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :id="'argument' + i + 'NameInput'"
                                                        :placeholder="'Argument ' + i + ' Name'"
                                                        v-model="requestData.arguments[i - 1].name"
                                                    />
                                                </div>
                                                <div class="col-md-8 mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :id="'argument' + i + 'DescriptionInput'"
                                                        :placeholder="'Argument ' + i + ' Description'"
                                                        v-model="requestData.arguments[i - 1].description"
                                                    />
                                                </div>
                                                <div class="col-md-8 mb-3">
                                                    <select class="form-control" :id="'argument' + i + 'TypeInput'" v-model="requestData.arguments[i - 1].type">
                                                        <option value="" selected disabled>Choisissez une type de votre argument</option>
                                                        <option v-for="typeValue in typeValue" :key="typeValue" :value="typeValue">{{ typeValue }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <button class="btn btn-primary" @click="addResquest">Ajouter la requête à la base</button>
                                    </div>
                                </b-card-text>
                            </b-tab>
                        </div>
                        <div v-else>
                            <p>Chargement en cours</p>
                            <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner"></b-spinner>
                        </div>
                    </div>
                </b-tabs>
            </b-card>
        </div>

        <b-modal id="use-modal" centered title="Add of arguments value of SQL Query">
            <form @submit.prevent="submitForm">
                <div v-for="(name, index) in arg.name" :key="index">
                    <label :for="'nameInput' + index">{{ name }} - {{ arg.description[index] }}</label>
                    <input :id="'nameInput' + index" :type="arg.type[index]" class="form-control" v-model="formData[index]" :placeholder="arg.type[index]" />
                </div>
            </form>
            <template v-slot:modal-footer>
                <b-button variant="primary" @click="useRequest">Confirmer</b-button>
                <b-button variant="danger" @click="hideModal('use-modal')">Close</b-button>
            </template>
        </b-modal>

        <b-modal id="validation-modal" centered title="Validation of the SQL request" size="lg">
            <div style="max-height: 45rem; overflow-x: auto; overflow-y: auto">
                <pre style="font-size: 100%; display: contents">{{ routeResults }}</pre>
            </div>

            <template v-slot:modal-footer>
                <b-button variant="primary" @click="getResult">Confirmer</b-button>
                <b-button variant="danger" @click="toggleModals('add-modal', 'validation-modal')">Fermer</b-button>
            </template>
        </b-modal>

        <b-modal id="results-modal" centered title="Result of the SQL request" size="xl" class="result-modal-style">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th v-for="(header, index) in resultRequest.header" :key="index">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, rowIndex) in resultRequest.rows" :key="rowIndex">
                            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="info" @click="downloadCSV">Télecharger</b-button>
                <b-button variant="danger" @click="hideModal('results-modal')">Fermer</b-button>
            </template>
        </b-modal>

        <b-modal id="modal-center" centered title="Sql Query" size="lg">
            <b-card class="my-4">
                <pre style="font-size: 100%; display: contents">{{ modalContent }}</pre>
            </b-card>
            <template v-slot:modal-footer>
                <b-button v-if="role === 1" variant="primary" @click="openModifyModal">Modifier</b-button>
                <b-button variant="danger" @click="hideModal('modal-center')">Close</b-button>
            </template>
        </b-modal>

        <b-modal id="modify-modal" centered title="Modifier le code SQL" size="lg">
            <textarea
                class="form-control md-15 mb-2"
                v-model="editingCode"
                :style="{
                    height: editingCode.split('\n').length + 6 + 'em',
                    overflow: 'auto',
                    width: '555rem',
                    'max-width': '100%',
                    'white-space': 'nowrap',
                }"
            ></textarea>
            <div v-if="argNumber > 0" class="form-group col-md-2 mb-2">
                <label :for="argNumber">Nombre d'arguments:</label>
                <input type="number" class="form-control" v-model="argNumber" @input="generateArgumentFieldsForModify" />
            </div>
            <div style="overflow-x: hidden; overflow-y: auto; max-height: 13rem">
                <div v-for="i in argumentFieldsForModify.length" :key="i" style="max-width: 55rem">
                    <div class="row">
                        <div class="col-md-3 mb-1">
                            <input type="text" class="form-control" :id="'argument' + i + 'NameInput'" :placeholder="'Argument ' + i + ' Name'" v-model="requestUpdate.arg[i - 1].name" />
                        </div>
                        <div class="col-md-5 mb-2">
                            <input
                                type="text"
                                class="form-control"
                                :id="'argument' + i + 'DescriptionInput'"
                                :placeholder="'Argument ' + i + ' Description'"
                                v-model="requestUpdate.arg[i - 1].description"
                            />
                        </div>
                        <div class="col-md-2 mb-2">
                            <select class="form-control" :id="'argument' + i + 'TypeInput'" v-model="requestUpdate.arg[i - 1].type">
                                <option value="" selected disabled>Choisissez une type de votre argument</option>
                                <option v-for="typeValue in typeValue" :key="typeValue" :value="typeValue">{{ typeValue }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="primary" @click="performModify">Sauvegarder</b-button>
                <b-button variant="danger" @click="toggleModals('modal-center', 'modify-modal')">Annuler</b-button>
            </template>
        </b-modal>

        <b-modal id="enter-brand-modal" ref="enterBrandModal" centered title="Saisir la marque">
            <select class="form-control" v-model="enteredBrand">
                <option value="" selected disabled>Choisissez une marque</option>
                <option v-for="brand in brands" :key="brand.value" :value="brand.value">{{ brand.label }}</option>
            </select>
            <template v-slot:modal-footer>
                <b-button variant="primary" @click="saveBrand" :disabled="enteredBrand === ''">Continuer</b-button>
                <b-button variant="danger" @click="hideModal('enter-brand-modal')">Annuler</b-button>
            </template>
        </b-modal>

        <b-modal id="add-modal" centered title="Add of arguments value of SQL Query">
            <form @submit.prevent="submitForm">
                <div v-for="(name, index) in arg.name" :key="index">
                    <label :for="'nameInput' + index">{{ name }} - {{ arg.description[index] }}</label>
                    <input :id="'nameInput' + index" :type="arg.type[index]" class="form-control" v-model="formData[index]" :placeholder="arg.type[index]" />
                </div>
            </form>
            <template v-slot:modal-footer>
                <b-button variant="primary" @click="useRequest">Confirmer</b-button>
                <b-button variant="danger" @click="hideModal('add-modal')">Close</b-button>
            </template>
        </b-modal>

        <div class="loading-screen" v-if="isLoading">
            <div class="d-flex justify-content-center mb-3">
                <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner"></b-spinner>
            </div>
        </div>

        <b-modal id="none-data" centered title="No data">
            <p>Pas de données avec les arguments utilisés</p>
            <template v-slot:modal-footer>
                <b-button variant="primary" @click="toggleModals('add-modal', 'none-data')">Change Params </b-button>
                <b-button variant="danger" @click="closeModalNoData">Close</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { format } from '@fast-csv/format';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    export default {
        data() {
            return {
                searchQuery: '',
                currentPage: 1,
                perPage: 6,
                argumentFields: [],
                tableFields: ['Name', 'Description', 'Base', 'Request'],

                requestData: {
                    name: '',
                    description: '',
                    base: '',
                    code: '',
                    arguments: [],
                },
                requestUpdate: {
                    code: '',
                    request_code: '',
                    arg: [],
                },
                argNameToUpdate: '',
                argNumber: 0,
                argFields: [],
                argumentFieldsForModify: [],

                argument: '',
                arg: {
                    name: [],
                    description: [],
                    type: [],
                },
                typeValue: ['text', 'number', 'date'],
                SearchName: [],
                searchArgumentCount: 0,

                allRequest: [],
                modalContent: '',
                formData: [],

                valueToUse: '',
                codeToUse: '',
                baseToUse: '',
                editingCode: '',
                requestCodeToUpdate: '',
                routeResults: '',
                resultRequest: '',
                enteredBrand: '',
                selectedBrand: '',

                blobData: [],
                descriptionToUse: '',
                nameReq: '',

                brands: [],
                baseName: [],

                role: 0,
                isLoading: false,
                loadingData: true,
            };
        },
        computed: {
            formattedData() {
                return this.allRequest.map((item) => ({
                    Code: item.request_code,
                    Name: item.name,
                    Description: item.description,
                    Base: item.data_base,
                    Request: item.code,
                    Details: item.details,
                }));
            },

            filteredData() {
                const query = this.searchQuery.toLowerCase();
                return this.formattedData.filter((item) => {
                    return (
                        item.Code.toLowerCase().includes(query) ||
                        item.Name.toLowerCase().includes(query) ||
                        item.Description.toLowerCase().includes(query) ||
                        item.Base.toLowerCase().includes(query) ||
                        item.Details.toLowerCase().includes(query)
                    );
                });
            },
        },
        methods: {
            handlePageChange(page) {
                this.currentPage = page;
            },

            generateArgumentName() {
                const regex = /(@[a-zA-Z0-9_]*)|([a-zA-Z0-9_]+@[a-zA-Z0-9_]*)/g;
                const matches = this.requestData.code.match(regex);
                if (matches) {
                    const firstGroupMatches = matches.filter((match) => match.startsWith('@'));
                    this.SearchName = firstGroupMatches;
                } else {
                    this.SearchName = [];
                }
            },

            generateArgumentFields() {
                this.generateArgumentName();
                this.searchArgumentCount = this.SearchName.length;
                this.argumentFields = Array.from({ length: this.SearchName.length }, (_, i) => i + 1);
                this.requestData.arguments = Array.from({ length: this.SearchName.length }, (_, i) => ({
                    name: this.SearchName[i],
                    description: '',
                    type: this.typeValue[i],
                }));
            },

            clearResquestData() {
                this.requestData.name = '';
                this.requestData.description = '';
                this.requestData.base = '';
                this.requestData.code = '';
                this.requestData.arguments = [];
                this.searchArgumentCount = 0;
                this.argumentFields = [];
            },

            addResquest() {
                this.isLoading = true;
                const detailsObject = {
                    name: [],
                    description: [],
                    type: [],
                };
                for (let i = 1; i <= this.searchArgumentCount; i++) {
                    const argumentName = document.querySelector(`#argument${i}NameInput`).value;
                    const argumentDescription = document.querySelector(`#argument${i}DescriptionInput`).value;
                    const argumentType = document.querySelector(`#argument${i}TypeInput`).value;
                    detailsObject.name.push(argumentName);
                    detailsObject.description.push(argumentDescription);
                    detailsObject.type.push(argumentType);
                }
                this.requestData.details = JSON.stringify(detailsObject);
                this.$store.dispatch('app/addSql', this.requestData).then((res) => {
                    if (res.status === 200) {
                        this.isLoading = false;
                        this.clearResquestData();
                        this.fetchRequest();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Bien ajouter a la base',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    } else {
                        this.isLoading = false;
                        throw error;
                    }
                });
            },

            fetchRequest() {
                this.$store.dispatch('app/getAllSqlRequest').then((res) => {
                    if (res.status === 200) {
                        this.allRequest = res.data;
                    } else {
                    }
                });
                return true;
            },

            showModal(code, request_code, argName) {
                this.argNameToUpdate = argName;
                this.requestCodeToUpdate = request_code;
                this.modalContent = `${code}`;
                this.$bvModal.show('modal-center');
            },

            modalUse(code, details, base, description, name) {
                if (base !== 'customer') {
                    this.descriptionToUse = description;
                    this.nameReq = name
                    this.baseToUse = base;
                    this.codeToUse = code;
                    this.arg = JSON.parse(details);
                    this.argument = details;
                    this.formData = Array(this.arg.name.length).fill('');
                    this.editingCode = code;
                    if (details != '{"name":[],"description":[],"type":[]}') {
                        this.$bvModal.show('use-modal');
                    } else {
                        this.useRequest();
                    }
                } else {
                    this.choseBrand(base, code, details, description);
                }
            },

            choseBrand(base, code, details, description) {
                this.descriptionToUse = description;
                this.baseToUse = base;
                this.codeToUse = code;
                this.arg = JSON.parse(details);
                this.argument = details;
                this.formData = Array(this.arg.name.length).fill('');
                this.editingCode = code;
                this.$bvModal.show('enter-brand-modal');
            },

            saveBrand() {
                this.baseToUse = this.baseToUse + '_' + this.enteredBrand;
                this.$bvModal.hide('enter-brand-modal');
                if (JSON.stringify(this.arg) != '{"name":[],"description":[],"type":[]}') {
                    this.$bvModal.show('add-modal');
                } else {
                    this.useRequest();
                }
            },

            useRequest() {
                this.isLoading = true;
                if (!this.arg || this.arg.name.length === 0) {
                    this.openResultsModal(this.codeToUse);
                    this.hideModal('use-modal');
                    return;
                }
                this.valueToUse = JSON.stringify(this.formData);
                this.$store
                    .dispatch('app/GetRequestSql', { code: encodeURIComponent(this.codeToUse), details: this.argument, value: this.valueToUse, base: this.baseToUse })
                    .then((res) => {
                        if (res.status === 200) {
                            this.isLoading = false;
                            this.openResultsModal(res.data);
                            this.hideModal('use-modal');
                        } else {
                            this.isLoading = false;
                            throw error;
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.error(error);
                    });
            },

            convertToCSV(data) {
                const header = Object.keys(data[0]);
                const firtsTenRows = data.slice(0, 10);
                const rows = firtsTenRows.map((obj) => Object.values(obj));
                const row = data.map((obj) => Object.values(obj));
                return { header, rows, row };
            },

            openModalResult(results) {
                this.resultRequest = this.convertToCSV(results);
                this.$bvModal.show('results-modal');
            },

            openModifyModal() {
                this.$bvModal.hide('modal-center');
                this.editingCode = this.modalContent;
                this.$bvModal.show('modify-modal');
            },

            generateArgumentFieldsForModify() {
                const regex = /(@[a-zA-Z0-9_]*)|([a-zA-Z0-9_]+@[a-zA-Z0-9_]*)/g;
                const matches = this.editingCode.match(regex);
                if (matches) {
                    const firstGroupMatches = matches.filter((match) => match.startsWith('@'));
                    this.argFields = firstGroupMatches;
                } else {
                    this.argFields = [];
                }
            },

            generateArgumentForModify() {
                this.generateArgumentFieldsForModify();
                this.argNumber = this.argFields.length;
                this.argumentFieldsForModify = Array.from({ length: this.argFields.length }, (_, i) => i + 1);
                this.requestUpdate.arg = Array.from({ length: this.argFields.length }, (_, i) => ({
                    name: this.argFields[i],
                    description: '',
                    type: this.typeValue[i],
                }));
            },

            performModify() {
                this.isLoading = true;
                this.requestUpdate.code = this.editingCode;
                this.requestUpdate.request_code = this.requestCodeToUpdate;
                this.$store.dispatch('app/modifSQL', this.requestUpdate).then((res) => {
                    if (res.status === 200) {
                        this.isLoading = false;
                        this.modalContent = this.requestUpdate.code;
                        this.toggleModals('modal-center', 'modify-modal');
                        this.fetchRequest();
                    } else {
                        this.isLoading = false;
                        throw error;
                    }
                });
            },

            openResultsModal(results) {
                this.isLoading = false;
                this.routeResults = results;
                this.$bvModal.show('validation-modal');
            },

            getResult() {
                this.isLoading = true;
                this.$store
                    .dispatch('app/getSqlRequestResult', {
                        code: this.routeResults,
                        base: this.baseToUse,
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            this.isLoading = false;
                            this.openModalResult(res.data);
                            this.$bvModal.hide('validation-modal');
                            this.fetchRequest();
                        } else {
                            this.isLoading = false;
                            console.error(res.statusText);
                        }
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.$bvModal.hide('validation-modal');
                        this.$bvModal.show('none-data');
                    });
            },

            downloadCSV() {
                const formattedCsv = format({ headers: true, delimiter: ';' });

                formattedCsv.write(this.resultRequest.header);

                this.resultRequest.row.forEach((row) => formattedCsv.write(row));

                formattedCsv.on('data', (chunk) => {
                    this.blobData.push(chunk);
                });

                formattedCsv.on('end', () => {
                    const blob = new Blob(this.blobData, { type: 'text/csv' });

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    if (this.enteredBrand != '') {
                        link.download = `${this.nameReq} ${this.enteredBrand}.csv`;
                    } else {
                        link.download = `${this.nameReq}.csv`;
                    }

                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    this.blobData = [];
                    this.enteredBrand = '';
                    this.hideModal('results-modal');
                    this.hideModal('add-modal');
                });
                formattedCsv.end();
            },

            fetchBrands() {
                this.$store.dispatch('app/getBrandForSqlRequest').then((res) => {
                    if (res.status === 200) {
                        this.brands = res.data.value.map((value, index) => ({
                            value: value,
                            label: res.data.message[index],
                        }));
                        this.baseName = res.data.base;
                    } else {
                        console.error('Erreur lors de la récupération des marques', error);
                    }
                });
                return true;
            },

            getRole() {
                const jwt = localStorage.getItem('accessToken');
                this.$store.dispatch('app/getRoleGeolink', jwt).then((res) => {
                    if (res.status === 200) {
                        const verifRole = res.data.adcleek;
                        for (let i = 0; i < verifRole.length; i++) {
                            const roleVerif = verifRole[i];
                            const value = roleVerif['service'];
                            if (value === 'geolink') {
                                this.role = roleVerif['rights']['role'];
                            } else {
                                this.role = 0;
                            }
                        }
                    } else {
                        console.error('Erreur lors de la récupération du role', error);
                    }
                });
                return true;
            },

            closeModalNoData() {
                this.$bvModal.hide('none-data');
                this.$bvModal.hide('add-modal');
            },

            hideModal(modal) {
                this.enteredBrand = '';
                this.isLoading = false;
                this.$bvModal.hide(modal);
            },

            toggleModals(m1, m2) {
                this.$bvModal.show(m1);
                this.$bvModal.hide(m2);
            },

            async fetchAll() {
                this.loadingData = true;
                try {
                    await Promise.all([this.fetchRequest(), this.fetchBrands(), this.getRole()]);
                    this.loadingData = false;
                } catch (error) {
                    console.error('Error fetching all data:', error);
                    this.loadingData = false;
                }
            },
        },
        watch: {
            'requestData.code'(newVal, oldVal) {
                this.generateArgumentFields();
            },
            editingCode(newVal, oldVal) {
                this.generateArgumentForModify();
            },
        },

        mounted() {
            this.fetchAll();
        },
    };
</script>
